<template>
  <div>
    <recurringMenu @close="close()" @saveTemplate="save()" @changeTabs="changeTabs"/>
    <div v-if="template" class="col-12" style="padding:20px;">
      <!-- <a-tabs v-model="activeTabKey" :default-active-key="1"> -->
      <div v-if="activeTabKey === 1" >
                  <div class="row g-3 align-center">
              <div class="col-3">
                  <div class="form-group" style="margin-top: 3px;">
                    <em class="icon ni ni-building" style="padding-right: 5px;"/>
                      <label class="form-label" for="default-01">Account</label>
                  </div>
              </div>
              <div class="col-lg-9">
                  <div class="form-group">
                      <div class="form-control-wrap">
                          <a-select  show-search option-filter-prop="children" @change="accountChanged" class="invisibox" style="min-width: 300px; width: 100%;"  v-model="template.accountId" :class="{'is-error' : $v.template.accountId.$invalid }">
                            <a-select-option key="0">Select An Account...</a-select-option>
                            <a-select-option v-for="acc in accounts" v-bind:key="acc.id">
                                {{ acc.text }}
                            </a-select-option>
                          </a-select>
                      </div>
                  </div>
              </div>
          </div>
          <div v-if="template.accountId" class="row g-3 align-center">
              <div class="col-3">
                  <div class="form-group" style="margin-top: 3px;">
                    <em class="icon ni ni-user-fill" style="padding-right: 5px;"/>
                      <label class="form-label" for="default-01">Contact</label>
                  </div>
              </div>
              <div class="col-lg-9">
                  <div class="form-group">
                      <div class="form-control-wrap">
                        <a-input-group compact>
                          <a-button style="width: 15%; border-style: solid; " @click="showContactInfo(template.contactId)"><em class="icon ni ni-edit" style="fontSize: 16px;"/></a-button>
                          <a-select show-search option-filter-prop="children" class="invisibox" style="min-width: 300px; width: 85%;" v-model="template.contactId" :class="{'is-error' : $v.template.contactId.$invalid }">
                            <a-select-option key="0" @click="showContactInfo(template.contactId)">New Contact</a-select-option>
                            <a-select-option v-for="con in contacts" v-bind:key="con.id">
                                {{ con.firstName + ' ' + con.lastName }}
                            </a-select-option>
                          </a-select>
                        </a-input-group>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row g-3 align-center" style="position:relative;">
              <div class="col-3">
                  <div class="form-group" style="margin-top: -11px;">
                    <em class="icon ni ni-edit" style="padding-right: 5px;"/>
                    <label class="form-label" for="businessOwnership">Title</label>
                  </div>
              </div>
              <div class="col-lg-9">
                  <div class="form-group">
                      <div class="form-control-wrap">
                          <a-textarea
                            class="invisibox"
                            style="width: 100%;"
                            v-model="template.title"
                            placeholder="Provide some details for this ticket"
                            :auto-size="{ minRows: 2, maxRows: 6 }"
                            :class="{'is-error' : $v.template.title.$invalid }"
                          />
                      </div>
                  </div>
              </div>
          </div>
          <hr class="preview-hr">
          <div class="row g-3 align-center">
              <div class="col-3">
                  <div class="form-group" style="margin-top: 3px;">
                    <em class="icon ni ni-piority-fill" style="padding-right: 5px;"/>
                      <label class="form-label" for="businessOwnership">Issue Type</label>
                      <a-popover class="float-right" style="padding-top: 4px">
                        <template slot="content">
                          <p>This sets the issue type of a ticket. Default can be set on the account.</p>
                          <p>This will determine which ticket/time allocation is used.</p>
                        </template>
                        <span class="ni ni-info"/>
                      </a-popover>
                  </div>
              </div>
              <div class="col-lg-9">
                  <div class="form-group">
                      <div class="form-control-wrap">
                       <a-select class="invisibox" style="min-width: 300px; width: 100%;" placeholder="Select Issue Type" v-model="template.issueTypeId" :class="{'is-error' : $v.template.issueTypeId.$invalid }">
                          <a-select-option key="0">Select an Issue Type...</a-select-option>
                          <a-select-option  v-for="it in issueTypes" v-bind:key="it.id">
                              {{ it.name }}
                          </a-select-option>
                        </a-select>
                      </div>
                  </div>
              </div>
          </div>

          <div class="row g-3 align-center">
              <div class="col-3">
                  <div class="form-group" style="margin-top: 3px;">
                    <em class="icon ni ni-piority" style="padding-right: 5px;"/>
                      <label class="form-label" for="businessOwnership">Priority</label>
                      <a-popover class="float-right" style="padding-top: 3px">
                        <template slot="content">
                          <p>This sets the priority of a ticket. Default can be set on the account.</p>
                          <p>This will determine the SLA and position in the queue.</p>
                        </template>
                        <span class="ni ni-info"/>
                      </a-popover>
                  </div>
              </div>
              <div class="col-lg-9">
                  <div class="form-group">
                      <div class="form-control-wrap">
                       <a-select class="invisibox" style="min-width: 300px; width: 100%;" v-model="template.priorityId" :class="{'is-error' : $v.template.priorityId.$invalid }">
                          <a-select-option key="0">Select a priority...</a-select-option>
                          <a-select-option  v-for="priority in priorities" v-bind:key="priority.id">
                              {{ priority.text }}
                          </a-select-option>
                        </a-select>
                      </div>
                  </div>
              </div>
          </div>
          <hr class="preview-hr">
         <div class="row g-3 align-center">
              <div class="col-3">
                  <div class="form-group" style="margin-top: 3px;">
                    <em class="icon ni ni-user-alt" style="padding-right: 5px;"/>
                      <label class="form-label" for="default-01">Assign User</label>
                  </div>
              </div>
              <div class="col-lg-9">
                  <div class="form-group">
                      <div class="form-control-wrap">
                          <a-select show-search option-filter-prop="children" class="invisibox" style="min-width: 300px; width: 100%;"  v-model="template.assignedUserId">
                            <a-select-option key="0">Select A User</a-select-option>
                            <a-select-option v-for="u in users" v-bind:key="u.id">
                                {{ u.firstName + ' ' + u.lastName }}
                            </a-select-option>
                          </a-select>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row g-3 align-center">
              <div class="col-3">
                  <div class="form-group" style="margin-top: 3px;">
                    <em class="icon ni ni-users" style="padding-right: 5px;"/>
                      <label class="form-label" for="default-01">Assign Team</label>
                  </div>
              </div>
              <div class="col-lg-9">
                  <div class="form-group">
                      <div class="form-control-wrap">
                          <a-select class="invisibox" style="min-width: 300px; width: 100%;"  v-model="template.teamId">
                            <a-select-option key="0">Select A Team</a-select-option>
                            <a-select-option v-for="t in teams" v-bind:key="t.id">
                                {{ t.text }}
                            </a-select-option>
                          </a-select>
                      </div>
                  </div>
              </div>
          </div>
          
          
          <div>
            <hr class="preview-hr">
            <div class="col-12">
                <h6>Recurrence Settings</h6>
            </div>
            <div class="row">
                <div class="col-5">
                    <label class="form-label float-right pt-1">Start Date:</label>
                </div>
                <div class="col-6">
                    <a-date-picker show-time format="HH:mm a DD/MM/YY" v-model="template.startDate" class="invisibox"></a-date-picker>
                </div>
            </div>
            <div class="row">
                <div class="col-5">
                    <label class="form-label float-right pt-1">End Date:</label>
                </div>
                <div class="col-6">
                    <a-date-picker show-time format="HH:mm a DD/MM/YY" :disabled="disableEndDate" v-model="template.endDate" class="invisibox"></a-date-picker>
                </div>
            </div>
            <div class="row">
                <div class="col-5">
                    <label class="form-label float-right pt-1">Has No End Date:</label>
                </div>
                <div class="col-6">
                    <a-checkbox v-model="disableEndDate" class="pt-1"></a-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <table class="table table-striped">
                        <thead class="text-center bg-dark text-white">
                            <th>Mon</th>
                            <th>Tues</th>
                            <th>Weds</th>
                            <th>Thurs</th>
                            <th>Fri</th>
                            <th>Sat</th>
                            <th>Sun</th>
                        </thead>
                        <tbody class="text-center">
                            <tr>
                                <td><a-checkbox v-model="template.monday"></a-checkbox></td>
                                <td><a-checkbox v-model="template.tuesday"></a-checkbox></td>
                                <td><a-checkbox v-model="template.wednesday"></a-checkbox></td>
                                <td><a-checkbox v-model="template.thursday"></a-checkbox></td>
                                <td><a-checkbox v-model="template.friday"></a-checkbox></td>
                                <td><a-checkbox v-model="template.saturday"></a-checkbox></td>
                                <td><a-checkbox v-model="template.sunday"></a-checkbox></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-5">
                    <label class="form-label float-right pt-1">Every Week:</label>
                </div>
                <div class="col-6">
                    <a-switch v-model="template.weekly" @change="disableRecurrenceBoxes()" :disabled="everyWeek" class="pt-1"></a-switch>
                </div>
                <div class="col-5">
                    <label class="form-label float-right pt-1">Bi-Weekly:</label>
                </div>
                <div class="col-6">
                    <a-switch v-model="template.biweekly" @change="disableRecurrenceBoxes()" :disabled="every2Weeks" class="pt-1"></a-switch>
                </div>
                <div class="col-5">
                    <label class="form-label float-right pt-1">Monthly:</label>
                </div>
                <div class="col-6">
                    <a-switch v-model="template.monthly" @change="disableRecurrenceBoxes()" :disabled="everyMonth" class="pt-1"></a-switch>
                </div>
                <div class="col-5" v-if="template.monthly">
                  <label class="form-label float-right pt-1">Occurs on</label>
                </div>
                <div class="col-6" v-if="template.monthly">
                  <a-input-number v-model="template.dayNumber"  :min="1" :max="31" />
                </div>
                <div class="col-5" v-if="template.monthly">
                  <label class="form-label float-right pt-1">First Day of the Month</label>
                </div>
                <div class="col-6" v-if="template.monthly">
                  <a-switch v-model="template.firstDay"  />
                </div>
                <div class="col-5" v-if="template.monthly">
                  <label class="form-label float-right pt-1">Last Day of the Month</label>
                </div>
                <div class="col-6" v-if="template.monthly">
                  <a-switch v-model="template.lastDay"  />
                </div>
                <div class="col-5">
                    <label class="form-label float-right pt-1">Annually:</label>
                </div>
                <div class="col-6">
                    <a-switch v-model="template.annually" @change="disableRecurrenceBoxes()" :disabled="annually" class="pt-1"></a-switch>
                </div>
                <div class="col-5" v-if="template.annually">
                  <label class="form-label float-right pt-1">Occurs on</label>
                </div>
                <div class="col-6" v-if="template.annually">
                  <a-date-picker v-model="template.annualDate" class="invisibox" />
                </div>
                <div class="col-5">
                    <label class="form-label float-right pt-1">Every Week(s):</label>
                </div>
                <div class="col-6">
                    <a-input-number v-model="template.everyXWeeks" :min="1" :disabled="everyXWeeks" class="invisibox"></a-input-number>
                </div>
            </div>
          </div>
          
      </div>
      
    <!-- </a-tabs>   -->
     </div>
     <a-modal v-model="showContact" title="Contact Info">
       <div class="row">
         <div class="col-12">
            <label class="form-label">First Name</label>
            <a-input class="invisibox" v-model="selectedContact.firstName" />
         </div>
       </div>
       <div class="row">
         <div class="col-12">
            <label class="form-label">Last Name</label>
            <a-input class="invisibox" v-model="selectedContact.lastName" />
         </div>
       </div>
       <div class="row">
         <div class="col-12">
           <label class="form-label">Authorisation Access</label>
           <a-select placeholder="Select Auth Access" class="invisibox" v-model="selectedContact.authorisationAccessId" style="width: 100%">
                <a-select-option :value="null" > Not selected </a-select-option> 
                <a-select-option v-for="authorisationAccess in authorisationAccesses" v-bind:key="authorisationAccess.id">
                    {{ authorisationAccess.text }}
                </a-select-option>
            </a-select>
         </div>
       </div>
       <div class="row">
         <div class="col-12">
            <label class="form-label">Email</label>
            <a-input class="invisibox" v-model="selectedContact.email" />
         </div>
       </div>
       <div class="row">
         <div class="col-12">
            <label class="form-label">Phone</label>
            <a-input class="invisibox" v-model="selectedContact.phoneNumber" />
         </div>
       </div>
       <div class="row">
         <div class="col-12">
            <label class="form-label">Mobile</label>
            <a-input class="invisibox" v-model="selectedContact.mobile" />
         </div>
       </div>
       <template slot="footer">
         <a-button class="btn btn-sm btn-success" @click="saveContact()">
           <em class="icon ni ni-save"></em>Save
         </a-button>
       </template>
     </a-modal>
    
    <a-modal v-model="search" title="Search Accounts / Contacts">
       <div class="row">
         <div class="col-12">
            <input type="text" v-model="searchFilter" class="mb-2 form-control border-transparent form-focus-none" placeholder="Search Accounts / Contacts">
         </div>
       </div>
       <div class="row" v-for="a in filteredAccountsAndContacts" :key="a.id">
         <div class="col-12">
            <a-button style="width: 100%" @click="selectContact(a)">
              {{ a.name + ' | ' + a.accountName }}
            </a-button>
         </div>
       </div>
     </a-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import recurringMenu from '@/components/desk/tickets/recurring/recurringMenu.vue'

export default {
    components: {
        recurringMenu
    },
    props: {
        recurringTicketTemplate: Object
    },
  validations: {
    template: {
      accountId: {
        required
      },
      title: {
        required
      },
      contactId: {
        required
      },
      statusId: {
        required
      },
      issueTypeId: {
        required
      },
      priorityId: {
        required
      }
    }
  },
  data () {
      return {
        accountIssueTypeUsage: '',
        searchFilter: '',
        accountsAndContacts: null,
        search: false,
        activeTabKey: 1,
        showContact: false,
        selectedContact: {},
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
        priorities: [],
        accounts:[],
        contacts: [],
        issueTypes: [],
        users: [],
        teams: [],
        authorisationAccesses: [],
        disableEndDate: false,
        everyWeek: false,
        every2Weeks: false,
        everyXWeeks: false,
        everyMonth: false,
        annually: false,
        template: {},
      }
  },
  created () {
        this.getLists()
        if (this.recurringTicketTemplate.id) {
            this.setupTemplate()
        } 
  },
  computed: {
        filteredAccountsAndContacts: function() {
            if (this.searchFilter != '') {
                return this.accountsAndContacts.filter((f) => {  return f.name.toLowerCase().match(this.searchFilter.toLowerCase()) })
            }
            return this.accountsAndContacts 
        }
    },
  watch: {
        recurringTicketTemplate: function() {
            if (this.recurringTicketTemplate.id) {
                this.setupTemplate()
            } else {
                this.template = { startDate: new Date() }
            }
        }
    },
  methods: {
    setupTemplate() {
        this.template = this.recurringTicketTemplate
        this.template.startDate = this.$moment.utc(this.template.startDate).local()
        if ( this.template.endDate) {
          this.template.endDate = this.$moment.utc(this.template.endDate).local()
        }
        if( this.template.endDate == null) {
            this.disableEndDate = true
        }
        if (this.template.dayNumber != null || this.template.firstDay == true || this.template.lastDay == true) {
            this.template.everyMonth = true
        }
        this.resetRecurrenceBoxes()
        this.getContacts()
    },
    issueTypeChanged () {
      this.get_IssueUsage()
    },
    accountChanged () {
      this.getContacts()
    },
    selectContact(c){
      this.template.accountId = c.accountId
      this.template.contactId = c.id
      this.search = false
    },
    changeTabs (tabName) {
      if (tabName === 'details'){ this.activeTabKey = 1}
      if (tabName === 'notes'){ this.activeTabKey = 2 }
      if (tabName === 'attachments'){ this.activeTabKey = 3 }
      if (tabName === 'timeline'){ this.activeTabKey = 4 }
      if (tabName === 'timeAllocation'){ this.activeTabKey = 6 }
      if (tabName === 'status'){ this.activeTabKey = 5 }
    },
    closeAddTag() {
      this.displayAddTag = false
    },
    addNewContact() {
      this.$http.get('/contacts/get_Contact/')
      .then((response) => {
          this.selectedContact = response.data
      })
      .catch(() => {
      this.$message.error('There has been an error')
      })
      this.showContact = true
    },
    showContactInfo(id){
      let urI = id ? ('/contacts/get_Contact/' + id ) : '/contacts/get_Contact/' 
      this.$http.get(urI)
      .then((response) => {
          this.selectedContact = response.data
      })
      .catch(() => {
        this.$message.error('There has been an error')
      })
      this.showContact = true
    },
    addRecurringTicketTemplate() {
       this.$http.post('/desk/Add_RecurringTicketTemplate/', this.template)
      .then(() => {
        this.$emit('ticketSaved')
      })
      .catch(() => {
        this.$message.error('There has been an error')
      })  
    },
    getLists(){
        this.$http.get('/lists/Get_List/ticketPriorities')
        .then((response) => {
          this.priorities = response.data
          })
        .catch(() => {
          this.$message.error('There has been an error')
        })
        this.$http.get('/settings/Get_Tenant_TicketIssueTypes')
        .then((response) => {
            this.issueTypes = response.data
        })
        .catch(() => {
            this.$message.error('There has been an error')
        })
        this.$http.get('/lists/Get_List/teams')
        .then((response) => {
            this.teams = response.data
        })
        .catch(() => {
            this.$message.error('There has been an error')
        })
        this.$http.get('/lists/Get_List/authorisationAccess')
          .then((response) => {
              this.authorisationAccesses = response.data
          })
          .catch(() => {
          this.$message.error('There has been an error')
        })
        this.$http.get('/lists/Get_List/accounts')
        .then((response) => {
            this.accounts = response.data
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
        this.$http.get('/lists/Get_Users')
        .then((response) => {
            this.users = response.data
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
      },
      getAccountDefaults() {
        this.$http.get('/accounts/Get_Account_Defaults/' + this.template.accountId)
        .then((response) => {
            this.template.priorityId = response.data[0].defaultTicketPriority
            this.template.issueTypeId = response.data[0].defaultTicketIssueType
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
      },
      getContacts() {
        this.$http.get('/accounts/Get_Account_Contacts/' + this.template.accountId)
        .then((response) => {
            this.contacts = response.data
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
      },
      saveContact () {
        if (this.selectedContact.id != null)    {
            this.updateContact()
        } 
        else {
            this.saveNewContact()
        }
      },
      saveNewContact() {
        this.selectedContact.accountId = this.template.accountId
        this.$http.post('/contacts/Add_Contact', this.selectedContact)
        .then((resp) => {
            this.template.contactId = resp.data.id
            this.$message.success('Contact Created')
            this.getContacts()
            this.showContact = false
        })
        .catch(() => {
            this.$message.error('There has been an error')
        })
      },
      updateContact() {
        this.$http.post('/contacts/Update_Contact', this.selectedContact)
        .then(() => {
            this.$message.success('Contact Updated')
            this.getContacts()
            this.showContact = false
        })
        .catch(() => {
            this.$message.error('There has been an error')
        })
      },
      resetRecurrenceBoxes() {
            this.every2Weeks = false
            this.everyXWeeks = false
            this.everyMonth = false
            this.everyWeek = false
            this.annually = false
      },
      disableRecurrenceBoxes() {
            if(this.template.weekly == true){
                this.every2Weeks = true
                this.everyXWeeks = true
                this.everyMonth = true
                this.everyWeek = false
                this.annually = true
                this.template.everyXWeeks = 1
            } 
            else if ( this.template.biweekly == true) {
                this.everyWeek = true
                this.everyXWeeks = true
                this.everyMonth = true
                this.every2Weeks = false
                this.annually = true
                this.template.everyXWeeks = 2
            }
            else if ( this.template.annually == true) {
                this.everyWeek = true
                this.everyXWeeks = true
                this.everyMonth = true
                this.every2Weeks = true
                this.annually = false
                this.template.everyXWeeks = 1
            }
            else if ( this.template.monthly == true) {
                this.everyWeek = true
                this.everyXWeeks = true
                this.every2Weeks = true
                this.everyMonth = false
                this.annually = true
            }
            else {
                this.everyWeek = false
                this.every2Weeks = false
                this.everyMonth = false
                this.everyXWeeks = false
                this.annually = false
                this.template.everyXWeeks = 1
            }
        },
        save() {
            if ( this.template.id ) {
                this.$http.post('/desk/Update_RecurringTicketTemplate', this.template)
                .then(() => {
                    this.$message.success('Template Updated')
                    this.template = {}
                    this.$emit('templateSaved')
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            } else {
                this.$http.post('/desk/Add_RecurringTicketTemplate', this.template)
                .then(() => {
                    this.$message.success('Template Added')
                    this.template = {}
                    this.$emit('templateSaved')
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            }   
        },
        close() {
            this.template = {}
            this.$emit('close')
        },
  },
}
</script>

<style>
 
.nk-sticky-toolbar {
    position: absolute;
    width:100%;
    border: 3px solid #fff;
    top: 0px;
    background: #fff;
    z-index: 3000;
    left: 0px;
    border-radius: 6px 0 0 6px;
    border-right: 0;
    box-shadow: -2px 0 24px -2px rgb(43 55 72 / 15%);
}
.nk-sticky-toolbar li:not(:last-child) {
    border-bottom: 2px solid #fff;
    float:left;
}

.headbtnx {
    background-color: rgba(85,155,251,0.1);
    color: #559bfb;
}

.nk-sticky-toolbar li a {
    display: flex;
    height: 36px;
    width: 36px;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: inherit;
}
.footer {
  position: fixed;
  bottom: 0;
  margin: 1em;
  width: 21%;
  max-width: 21%;
  max-height: 95%;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}
</style>