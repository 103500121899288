<template>
    <div class="ticketMenu">
        <div class="row">
            <div class="col-12">
                <div class="float-left" style="margin-left:20px;">
                    <a href="#" @click="saveTicket()" title="Save Ticket" class="mr-2 btn btn-icon btn-dim btn-outline-success btn-round"><em class="icon ni ni-save"></em></a>
                    <span @click="changeComponent('details')" class="mr-2 text-dark" style="font-weight:800; padding: 5px; font-size: 17px;">Template Details</span>

                </div>
                <div class="float-right " style="margin-right:20px;">
                    <a href="#" @click="changeComponent('notes')" class="mr-2 btn btn-icon btn-dim btn-outline-dark btn-round" ><em class="icon ni ni-edit"/></a>
                    <a href="#" @click="changeComponent('attachments')" class="mr-2 btn btn-icon btn-dim btn-outline-dark btn-round" ><em class="icon ni ni-clip"/></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    methods: {
        changeComponent (changeTo) {
            this.$emit('changeTabs',changeTo)
        },
        closeAppointment() {
            this.$emit('close')
        },
        saveTicket () {
         this.$emit('saveTemplate')
    },
    }
}
</script>

<style scoped>
.ticketMenuDetails {
    font-size: 16px;
    line-height: 17px;
    width:285px;
}
.ticketMenu {
    height: '2%';
    color: #fff;
    width:100%;
    padding-top: 7px;
}
.ticketMenu em {
    font-size: 20px;
}
.ticketMenu .ticketMenuItem{
    float: left;
    padding: 5px 10px;
    border-right: 1px solid #e8e8e8;
    cursor:pointer; 
    height:30px !important; 
}
.ticketMenu .ticketMenuItem .ticketMenuName{
         margin-top: 3px;
}
.ticketMenu .ticketMenuItem:hover{
        background-color: #1c95c1;
}

.ticketMenu .ticketMenuItemSave{
    background-color:#1ee0ac; 
    float: left;
    padding: 5px 10px;
    border-right: 1px solid #e8e8e8;
    cursor:pointer; 
    height:30px !important;
}

.ticketMenu .ticketMenuItemSave:hover{
    background-color: #19be92;
}
</style>