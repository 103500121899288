<template>
  <div>
      <div class="row">
          <div class="col-12">
              <router-link :to="{name: 'tickets'}"><a-button class="btn btn-dark float-right pt-1"><em class="icon ni ni-arrow-left-circle" style="padding-right: 5px"/> Back To Tickets</a-button></router-link>
              <a-button class="btn btn-info float-right" style="margin-right: 10px;" @click="addNewTemplate()"><em class="icon ni ni-plus"/> Add Recurring Ticket</a-button>
              <h4 class="title">Recurring Tickets</h4>
          </div>
      </div>
      <div class="row">
          <div class="col-lg-12">
              <div class="card card-shadow">
                  <div class="card-inner">
                      <vue-good-table
                        :columns="rtColumns"
                        :rows="recurringTickets"
                      >
                        <template slot="table-row" slot-scope="props" >
                            <div v-if="props.column.field == 'actions'">
                                <a-button class="btn btn-dark btn-icon btn-round mr-2" @click="selectTemplate(props)"><em class="icon ni ni-edit"/></a-button>
                                <a-button class="btn btn-danger btn-icon btn-round mr-2"><em class="icon ni ni-delete"/></a-button>
                            </div>
                            <div v-else-if="props.column.field == 'createdDate'">
                                <span>{{ $moment(props.column.createdDate).format('HH:mm a DD/MM/YYYY')}}</span>
                            </div>
                        </template>
                      </vue-good-table>
                  </div>
              </div>
          </div>
      </div>
      <a-drawer :visible="visible" :closable="false" @close="onClose" :width="600"> 
          <recurringTicketsForm :recurringTicketTemplate="selectedTemplate" @close="close" @templateSaved="getRecurringTickets" />
      </a-drawer>
  </div>
</template>

<script>
import recurringTicketsForm from '@/components/desk/tickets/recurring/form.vue'

export default {
    components: { recurringTicketsForm },
    data() {
        return {
            visible: false,
            selectedTemplate: {},
            recurringTickets: null,
            rtColumns: [
                { label: 'Template Number', field: 'id', width: '7%' },
                { label: 'Title', field: 'title' },
                { label: 'Account', field: 'accountName' },
                { label: 'Contact', field: 'contactName' },
                { label: 'Priority', field: 'priorityName'},
                { label: 'Issue Type', field: 'issueTypeName'},
                { label: 'Created By', field: 'createdByUser' },
                { label: 'Created Date', field: 'createdDate' },
                { label: 'Actions', field: 'actions', tdClass: 'text-center', thClass: 'text-center', width: '10%'}
            ]
        }
    },
    created() {
        this.getView()
        this.getRecurringTickets()
    },
    methods: {
        getView() {
            this.$http.get('/ViewAccess/deskrecurringtickets')
            .then(() => {
            })
            .catch(() => {
            })
        },
        getRecurringTickets() {
            this.visible = false
            this.$http.get('/desk/Get_RecurringTicketTemplates')
            .then((response) => {
                this.recurringTickets = response.data
                this.selectedTemplate = {}
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        selectTemplate(props){
            this.selectedTemplate = props.row
            this.visible = true
        },
        onClose() {
            this.selectedTemplate = {}
            this.visible = false
        },
        addNewTemplate() {
            this.selectedTemplate = {}
            this.visible = true
        }
    }
}
</script>

<style>

</style>